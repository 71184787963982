import { RouteComponentProps } from 'react-router-dom';

export type PowerPointContentType =
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'application/vnd.openxmlformats-officedocument.presentationml.template'
  | 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
  | 'application/vnd.ms-powerpoint.addin.macroEnabled.12'
  | 'application/vnd.ms-powerpoint.presentation.macroEnabled.12'
  | 'application/vnd.ms-powerpoint.template.macroEnabled.12'
  | 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12';

export type MSWordContentType =
  | 'application/doc'
  | 'application/docs'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
  | 'application/vnd.ms-word.document.macroEnabled.12'
  | 'application/vnd.ms-word.template.macroEnabled.12';

export type ExcelContentType =
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
  | 'application/vnd.ms-excel.sheet.macroEnabled.12'
  | 'application/vnd.ms-excel.template.macroEnabled.12'
  | 'application/vnd.ms-excel.addin.macroEnabled.12'
  | 'application/vnd.ms-excel.sheet.binary.macroEnabled.12';

export type AttachmentContentType =
  | 'image/png'
  | 'image/jpeg'
  | 'application/pdf'
  | MSWordContentType
  | ExcelContentType
  | PowerPointContentType
  | 'application/vnd.ms-access';

export type AttachmentProps = {
  contentType: AttachmentContentType;
  name: string;
  attachmentId: string;
  size: number;
  handleDelete?: Function | null;
  handleOnClick?: Function;
  isLoading?: boolean;
};

export type Attachment = {
  attachmentId: string;
  contents: string;
  contentType: AttachmentContentType;
  name: string;
  size: number;
};

export type BreadCrumb = {
  id: string;
  title: string;
  navigateTo?: string;
};

export type ID = number | string;

export enum SaleTypeEnum {
  SINGLE = 'SINGLE',
  MIXED = 'MIXED',
  REGULAR = 'REGULAR',
  SINGLE_PORTFOLIO = 'SINGLE_PORTFOLIO',
  REGULAR_PORTFOLIO = 'REGULAR_PORTFOLIO',
}

export enum InstrumentTypeEnum {
  NEW = 'NEW',
  TRANSFER = 'TRANSFER',
  MODIFICATION_SINGLE = 'MODIFICATION_SINGLE',
  MODIFICATION_REGULAR = 'MODIFICATION_REGULAR',
}

export enum OperationEnum {
  PURCHASE = 'PURCHASE',
  SALE = 'SALE',
  TRANSFER_PURCHASE = 'TRANSFER_PURCHASE',
  TRANSFER_SALE = 'TRANSFER_SALE',
}

export type Investment = {
  entityId?: string;
  contractAcronym?: string;
  contractType: string;
  value: number;
  currency: string;
  fee: number;
  feeType?: number | string;
  length?: number;
  type: SaleTypeEnum;
  frequency?: number | string;
  operation?: OperationEnum;
  portfolio: boolean;
  product: {
    code: {
      code: string;
      displayValue: string;
    };
    name: string;
    category: string;
    type: string;
    templates: any;
    allowedCurrencies?: any[];
    url?: string;
    entityId?: any;
  };
  assetClasses: Array<any>;
  srri?: number;
  expensesRatio?: number;
  grossRevenue?: number;
  stockRatio?: number;
  perf1?: number;
  perf3?: number;
  perf5?: number;
  stdDeviation?: number;
  totalValue?: number;
  created: string;
  maxFee?: number;
  sellAll?: boolean;
  allowedCurrencies?: string[];
  targetYearsApplicable?: any;
};

type RiskProfileEnum = 'CONSERVATIVE' | 'BALANCED' | 'DYNAMIC';
type ClientExperienceEnum = 'BASIC' | 'ADVANCED' | 'INFORMED';

type ClientExperienceCategoryEnum = 'NONE' | 'UNKNOWN' | 'BASIC' | 'INFORMED' | 'ADVANCED';
type RiskToleranceValueEnum =
  | 'PROFILE_1'
  | 'PROFILE_2'
  | 'PROFILE_3'
  | 'PROFILE_4'
  | 'PROFILE_5'
  | 'PROFILE_6'
  | 'PROFILE_7';
type InvestmentHorizonEnum = 'MIN_8_YEARS' | 'MIN_5_YEARS' | 'MIN_3_YEARS' | 'MIN_1_YEAR' | 'MAX_1_YEAR';
type InvestmentPreferenceEnum = 'NO_LOSS_GUARANTEE' | 'PROTECTION' | 'INCREASE';

export type InvestmentQuestionnaireResultType = {
  clientExperienceCategory: ClientExperienceCategoryEnum;
  clientCategory: 'STANDARD';
  lossAcceptance: boolean;
  riskTolerance: RiskToleranceValueEnum;
  riskToleranceByFinanceSituation?: RiskToleranceValueEnum;
  investmentHorizon: InvestmentHorizonEnum;
  investmentPreference: InvestmentPreferenceEnum;
  environmentalSocialGovernance: {
    preference: 'NONE' | 'ARTICLE_8' | 'ARTICLE_9';
    share: 'NONE' | 'TO_25_PERCENT' | 'FROM_25_TO_50_PERCENT' | 'OVER_50_PERCENT';
    principalAdverseImpacts: string[];
  };
  sourceIp: null;
};

export enum InputType {
  DECIMAL = 'decimal',
  INTEGER = 'integer',
  HIDDEN = 'hidden',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  TEXT = 'text',
}

type ErrorAction = {
  actKey: string;
  actTitle: string;
  actUri?: string;
  actDetails?: string;
};

export type ErrorType = {
  status?: number;
  errorCode?: string;
  subcode?: string;
  occurrenceId?: string;
  actions?: Array<ErrorAction>;
  message: string;
};

export type ReducerParams = {
  type: string;
  payload: any;
};

export type ReducerState = {
  isLoading: boolean;
  error?: ErrorType | null;
};

export type OrderType = 'asc' | 'desc';

export enum PaymentFrequencyEnum {
  SINGLE = 'SINGLE',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export type Target = {
  answers: any;
  currency: string | null;
  entityId: string;
  investmentFrequency: PaymentFrequencyEnum;
  length: number | null;
  lengthValue: string;
  regularInvestment: number;
  singleInvestment: number;
  title: string | null;
  value: number | null;
  result: {
    profile: RiskProfileEnum;
    clientExperienceValue: ClientExperienceEnum;
    riskTolerance: number;
    lossAcceptance: boolean;
    investmentPreference: string;
    investmentHorizont: string;
    maxSrri: number;
  };
};

type TargetProductDeposit = {
  amount?: number;
  convertedAmount?: number;
  currency?: string;
  date?: string;
};

export type TargetProduct = {
  balance?: number;
  contractNumber?: string;
  created?: string;
  currency?: string;
  deposits?: Array<TargetProductDeposit>;
  editable?: boolean;
  entityId?: string;
  fundType?: string;
  knownValuation?: boolean;
  length?: number;
  name?: string;
  producer?: string;
  productType?: string;
  productValuation?: number;
  removable?: boolean;
  srri?: number;
  target?: number;
  targetLengthValue?: number;
  targetValuation?: number;
  quantity?: number;
  valuation?: number;
  yearDeposit?: number;
  url?: string;
};

export type TargetView = {
  products: Array<TargetProduct>;
  target: Target;
  targetName: string;
};

export type PartnerIdParam = {
  match: {
    params: {
      partnerId?: string;
    };
  };
  partnerId?: string;
};

export type HistoryPush = {
  history: {
    push: Function;
  };
};

export type WithRouterParams = {
  match: {
    params: any;
  };
} & HistoryPush;

export type PrintSend = {
  sendDateTime?: string;
  printDateTime?: string;
};

export type RoutedComponent = RouteComponentProps & {
  branchId: string;
  nextRoute?: string;
  prevRoute?: string;
  stepId?: string;
};

export type UserPermissionsProps = {
  ipAdmin: boolean;
  reporting: boolean;
  makeContract: boolean;
  intermediary: boolean;
  advisory: boolean;
};

// CLIENT

export type Address = {
  countryValue: Object;
  country: Object;
  street: string;
  displayValue: string;
  zip: string;
  city: string;
};

export type PersonalDocumentData = {
  issuer: string;
  number: string;
  type: 'NATIONAL_ID' | 'PASSOPORT';
  typeValue: Object;
  validFrom: number;
  validTo: number;
};

export type TaxData = {
  taxNumber: string;
  taxNumberIssued: string;
  taxNumberIssuedValue: Object;
  taxResidency: string;
  taxResidencyValue: Object;
};

export type PersonData = {
  account: string;
  accountCurrency: string;
  citizenship: string;
  citizenshipValue: Object;
  document: PersonalDocumentData;
  gender: 'MALE' | 'FEMALE';
  genderValue: Object;
  placeOfBirth: string;
  taxData: TaxData;
};

export type PersonContactData = {
  email: string;
  phone: string;
};

export type PersonTypeEnum = 'PHYSICAL' | 'LEGAL';

export type PhysicalPerson = {
  additionalData: PersonData;
  contact: PersonContactData;
  contactAddress: Address;
  dataProtections: {
    agreement: boolean;
    agreementEmailSent: boolean;
    agreementRequestSentOn: string;
  };
  dateOfBirth: number;
  entityId: string;
  firstname: string;
  foreignerWithoutPersonalNumber: boolean;
  fullname: string;
  hasDifferentContact: boolean;
  lastname: string;
  masterId: string;
  permanentAddress: Address;
  personalNumber: string;
  sendAgreementEmail: boolean;
  type: PersonTypeEnum;
};

export type LegalPerson = {
  companyName: string;
  companyNumber: number;
  contact: PersonContactData;
  entityId: string;
  masterId: string;
  permanentAddress: Address;
  hasDifferentContact: boolean;
  contactAddress: Address;
  type: PersonTypeEnum;
  additionalData: {
    account: string;
    accountCurrency: string;
    founded: string;
    foundingLocation: string;
    taxData: TaxData;
  };
};

export type Person = PhysicalPerson | LegalPerson;

export type Client = {
  entityId?: string;
  data?: Person;
  hasDeputy?: boolean;
  deputy?: PhysicalPerson;
};

export type SortByQuery = {
  sortBy: Object;
};

export type SortByTableProps = {
  sortBy: string;
  sortOrder: OrderType;
};

export type Paginatable = {
  page: number;
  size: number;
};

export type PartnerNavigationItem = {
  partnerName: string;
  partnerCode: string;
  selectable: boolean;
};

export type NumberOptions = {
  min?: number;
  max?: number;
  gt?: number;
  lt?: number;
};

export type PartnerData = {
  email: string;
  externalId: string;
  identificationNumber: string;
  partnerCode: string;
  partnerName: string;
  phone: string;
};

export type UserPermissions = {
  advisory: boolean;
  generalFlow: boolean;
  intermediary: boolean;
  ipAdmin: boolean;
  makeContract: boolean;
  reporting: boolean;
  assistant?: boolean;
};

export type UserInfo = {
  entityId?: string;
  firstname?: string;
  lastname?: string;
  btToken?: string;
  name?: string;
  partner?: PartnerData;
  permissions?: UserPermissions;
  username?: {
    name: string;
    displayValue: string;
  };
  email?: string;
  associatedPartners?: any[];
};

export type QuestionnaireAnswerProps = {
  index: number;
  text: string;
  picked: boolean;
  disabled: boolean;
  commentAllowed: boolean;
  comment?: string;
  prefilled: boolean;
  exclusiveAnswer?: boolean;
};

export type QuestionnaireQuestionProps = {
  answers: Array<QuestionnaireAnswerProps>;
  multiChoice: boolean;
  text: string;
  note?: string;
};

export type QuestionnaireProps = {
  label: string;
  last: boolean;
  question: QuestionnaireQuestionProps;
  required: boolean;
  section: number;
};

export type QuestionnairePreviouslyCompletedProps = {
  questionnaireId: string;
  completedAt: string;
};

export enum FeeTypeOptionEnum {
  STANDARD = 'STANDARD',
  EXPRESS = 'EXPRESS',
  COMMON = 'COMMON',
}

export type FeeType = {
  option: FeeTypeOptionEnum;
  extendedValue: string;
  value: string;
};

export enum BranchIdTypeEnum {
  ADVISORY = 'ADVISORY',
  INTERMEDIARY = 'INTERMEDIARY',
  EXECUTION_ONLY = 'EXECUTION_ONLY',
}

export enum QuestionTypeEnum {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}
